import regeneratorRuntime from "regenerator-runtime";


// ----------------------------------
//  Otvaranje menija za mob na klik
// ----------------------------------

export default () => {
    const iconOpen = document.querySelector('.header-nav-icon__menu--open');
    const iconClose = document.querySelector('.header-nav-icon__menu--close');
    const menu = document.querySelector('.header-nav');

    [iconOpen, iconClose].forEach(icon => {
        icon.addEventListener('click', function(e){
            const target = e.target.closest('.header-nav-icon__menu');
            
            if(target.classList =='header-nav-icon__menu header-nav-icon__menu--open'){
                // 1 - Prikaz menija
                menu.setAttribute('style', 'display:block');
    
                // 2 - Promena ikone
                iconOpen.setAttribute('style', 'display: none');
                iconClose.setAttribute('style', 'display: block');

            } else if(target.classList =='header-nav-icon__menu header-nav-icon__menu--close') {
                // 1 - Sakrivanje menija
                menu.setAttribute('style', 'display:none');
    
                // 2 - Promena ikone
                iconClose.setAttribute('style', 'display: none');
                iconOpen.setAttribute('style', 'display: block');
            }
        });
    });
}