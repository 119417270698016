import axios from 'axios';

import { hideAlert, showAlert } from './alert.js';



export async function addBag(){

    const sendForm = async (e) => {
        e.preventDefault();

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('name', document.getElementById('add-bag-name').value);
        form.append('brand', document.getElementById('add-bag-brand').value);
        form.append('for-models', document.getElementById('add-bag-for-models').value);
        form.append('price', document.getElementById('add-bag-price').value);
        form.append('description', document.getElementById('add-bag-description').value);
        form.append('imageCover', document.getElementById('add-bag-imageCover').files[0]);

        // console.log(document.getElementById('add-bag-imageCover').files[0]);

        // 2 - Provera unetih podataka
        if(!document.getElementById('add-bag-name').value) return showAlert('error', 'Obavezno je navesti naziv');
        if(!document.getElementById('add-bag-price').value) return showAlert('error', 'Obavezno je navesti cenu');
        if(!document.getElementById('add-bag-brand').value) return showAlert('error', 'Obavezno je navesti brend');
        if(!document.getElementById('add-bag-for-models').value) return showAlert('error', 'Obavezno je navesti kojim modelima kesa odgovara');
        if(!document.getElementById('add-bag-description').value) return showAlert('error', 'Obavezno je navesti opis');
        if(!document.getElementById('add-bag-imageCover').files[0]) return showAlert('error', 'Obavezno je uneti sliku');

        // 3 - http zahtev
        try {
            const res = await axios({
                method:'post',
                url: '/api/v1/bags',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste dodali proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.admin-form__btn--add-bag').addEventListener('click', sendForm);
}


export async function editBag(){

    const sendForm = async (e) => {
        e.preventDefault();

        const currentName = document.getElementById('edit-bag-curent-name').value;
        if(!document.getElementById('edit-bag-curent-name').value) return showAlert('error', 'Obavezno je navesti trenutni naziv');

        // console.log('currentName:', currentName);
        const slug = currentName.split(' ').join('_');
        // console.log('slug: ', slug);

        // 1 - Uzimanje vrednosti iz polja
        const brand = document.getElementById('edit-bag-brand').value;
        const forVacuumCleaners = document.getElementById('edit-bag-for-models').value;
        const price = document.getElementById('edit-bag-price').value;
        const description = document.getElementById('edit-bag-description').value;
        const imageCover = document.getElementById('edit-bag-imageCover').files[0];

        // 2 - Kreiranje simulirane multipart forme samo za popunjena polja
        const form = new FormData();
        if(brand) form.append('brand', brand);
        if(forVacuumCleaners) form.append('forVacuumCleaners', forVacuumCleaners);
        if(price) form.append('price', price);
        if(description) form.append('description', description);
        if(imageCover) form.append('imageCover', imageCover);

        // 3 - http zahtev
        try {
            const res = await axios({
                method:'patch',
                url: `/api/v1/bags/admin/${slug}`,
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste izmenili proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.admin-form__btn--edit-bag').addEventListener('click', sendForm);
}


export async function deleteBag(){

    const sendForm = async (e) => {
        e.preventDefault();

        const currentName = document.getElementById('delete-bag-curent-name').value;
        if(!document.getElementById('delete-bag-curent-name').value) return showAlert('error', 'Obavezno je navesti trenutni naziv');

        const slug = currentName.split(' ').join('_');

        try {
            const res = await axios({
                method:'delete',
                url: `/api/v1/bags/admin/${slug}`
            });

            if(res.status == 204){
                showAlert('success', 'Uspešno ste obrisali proizvod.');
                window.setTimeout(() => location.reload(), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.admin-form__btn--delete-bag').addEventListener('click', sendForm);
}


export async function addBrand(){

    const sendForm = async (e) => {
        e.preventDefault();

        // 1 - Kreiranje simulirane multipart forme
        const form = new FormData();
        form.append('name', document.getElementById('add-brand-name').value);
        form.append('imageLogo', document.getElementById('add-brand-imageLogo').files[0]);

        // 2 - Provera unetih podataka
        if(!document.getElementById('add-brand-name').value) return showAlert('error', 'Obavezno je navesti naziv');
        if(!document.getElementById('add-brand-imageLogo').files[0]) return showAlert('error', 'Obavezno je uneti sliku');

        // 3 - http zahtev
        try {
            const res = await axios({
                method:'post',
                url: '/api/v1/brands',
                data: form
            });

            if(res.data.status === 'success'){
                showAlert('success', 'Uspešno ste dodali brend.');
                window.setTimeout(() => location.reload(), 3 * 1000);
                
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.admin-form__btn--add-brand').addEventListener('click', sendForm);
}


export async function deleteBrand(){

    const sendForm = async (e) => {
        e.preventDefault();

        const currentName = document.getElementById('delete-brand-curent-name').value;
        if(!document.getElementById('delete-brand-curent-name').value) return showAlert('error', 'Obavezno je navesti trenutni naziv');

        const slug = currentName.split(' ').join('_');

        try {
            const res = await axios({
                method:'delete',
                url: `/api/v1/brands/admin/${slug}`
            });

            if(res.status == 204){
                showAlert('success', 'Uspešno ste obrisali brend.');
                window.setTimeout(() => location.reload(), 3 * 1000);
            }

        } catch (err) {
            showAlert('error', err.response.data.message);
            // showAlert('error', 'Doslo je do greske. Proverite da li ste ispravno uneli podatke.');
            // window.setTimeout(() => location.assign('/admin'), 3 * 1000);
        }

    }

    document.querySelector('.admin-form__btn--delete-brand').addEventListener('click', sendForm);
}