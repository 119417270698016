import axios from 'axios';

import { hideAlert, showAlert } from './alert.js';




export async function sendMessage(){
    let fullName;
    let email;
    let message;

    const orderBtn = document.querySelector('.contact-form-box .main-btn');

    const collectData = async () => {

        // 1 - Prikupljanje podataka

        fullName = document.getElementById('name').value;
        if(!fullName) return showAlert('error', 'Unesite ime');

        email = document.getElementById('email').value;
        if(!email) return showAlert('error', 'Unesite email');

        message = document.getElementById('message').value;
        if(!message) return showAlert('error', 'Unesite poruku');


        // 2 - Slanje emaila ka prodavcu

        try{
            const successfullMessage = await axios({
                url: '/email',
                method: 'post',
                data: {
                    email,
                    fullName,
                    message
                }
            });
            // console.log(successfullMessage); // **

            if(successfullMessage.status == 200){
                showAlert('success', 'Uspešno ste poslali poruku.');
    
                // 3 - Usmeravanje na zeljenu stranu
                window.setTimeout(() => location.assign('/'), 5 * 1000);
            }
        } catch(err){
            console.log(err);
        }

    }


    orderBtn.addEventListener('click', collectData);
}