export function additionalProductData(){
    // console.log('klik');

    let oznacenaKartica = document.querySelector('.tab-btn--active');
    let kartice = document.querySelector('.product-additional-details ul');

    // console.log(kartice);

    kartice.addEventListener('click', function(e){
        if (e.target.classList == 'tab-btn'){
            // 1 - Oznacavanje odabranog taba
            e.target.setAttribute('class', 'tab-btn--active');
            oznacenaKartica.setAttribute('class', 'tab-btn');
            oznacenaKartica = e.target;

            // 2 - Ubacivanje klase za kontrolni element
            // Na osnovu klase kontrolnog elementa ce se odredjivati koji podtab postaje vidljiv
            for (let i = 0; i < kartice.children.length; i ++){
                if (kartice.children[i].classList =='tab-btn--active'){
                    kartice.parentElement.setAttribute('class', `active-tab-${i}`);
                }
            }
        }
    });
}




// export function additionalProductData(){
//     document.getElementsByClassName('tab-btn')[0].setAttribute('class', 'tab-btn-active');
//     var oznacenaKartica = document.getElementsByClassName('tab-btn-active')[0];

//     var kartice = document.getElementsByClassName('tab-btns clearfix')[0];
//     kartice.addEventListener('click', function(e){
//         if (e.target.classList == 'tab-btn'){ 
//             e.target.setAttribute('class', 'tab-btn-active');
//             oznacenaKartica.setAttribute('class', 'tab-btn');
//             oznacenaKartica = e.target;

//             for (var i = 0; i < kartice.children.length; i ++){
//                 if (kartice.children[i].classList =='tab-btn-active'){
//                     kartice.parentElement.setAttribute('class', `active-tab-${i + 1}`);
//                 }
//             }
//         }
//     });
// }