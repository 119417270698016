



export function map(){
    const lat = 44.80674686296967;
    const lng = 20.406860356203502;

    // Making a map and tile
    const mymap = L.map('map', {
        scrollWheelZoom: false
    }).setView([lat, lng], 16);

    const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?';
    const tiles = L.tileLayer(tileUrl, { attribution });

    // Make a marker with a custom icon
    // var myIcon = L.icon({
    //     iconUrl: '/recources/img/tango5-logo.webp',
    //     iconSize: [30, 30],
    //     iconAnchor: [15, 15]
    // });

    tiles.addTo(mymap);
    const marker = L.marker([lat, lng]).addTo(mymap);
    // const marker = L.marker([lat, lng], {icon: myIcon}).addTo(mymap);

    // var circle = L.circle([lat, lng], {
    //     color: 'brown',
    //     fillColor: '#e4b06c',
    //     fillOpacity: 0.5,
    //     radius: 50
    // }).addTo(mymap);
}


