import axios from 'axios';


const form = document.querySelector('.header__search');
let searchTerms = [];
let input ='';


function autocompleteMatch(input) {
    if (input == '') return [];
    const reg = new RegExp(input)
    return searchTerms.filter(function(term) {
        if (term.toLowerCase().match(reg) || term.match(reg)) return term;
    });
}

 
function showResults(val) {
    const res = document.getElementById('result');
    res.innerHTML = '';
    let list = '';
    let terms = autocompleteMatch(val);
    //   list = terms.map(el => `<li>${el}</li>`).join('');
    list = terms.map(el => `<li><a href="${location.pathname.split('/')[0]}/pretraga?customQuery=${el}">${el}</a></li>`).join('');
    res.innerHTML = `<ul>${list}</ul>`;
}


async function matchedProducts(input){
    try{
        // Zahtev za proizvodima ka serveru
        let products = await axios({
            url: '/matchedProducts',
            method: 'post',
            data: {
                customQuery: input
            }
        });
        return products;
    } catch(err){
        console.log(err);
    }
}


export default async function(){
    form.addEventListener('keyup', async function(){
        try{
            searchTerms = [];
            const searchEl = document.querySelector('#result');
            input = document.querySelector('#vacuum-model').value;

            // Ako nema unosa, sakrij polje za asocijacije
            if(input.length == 0) return searchEl.setAttribute('style', 'display: none');

            // 1 - Dovedi podatke iz baze preko servera
            let productsJson = await matchedProducts(input);
            productsJson = productsJson.data.data.bagsTotal;
            // console.log(productsJson, typeof(productsJson), searchTerms.length); //

            // Ako nema poklapanja, sakrij polje za asocijacije
            if(productsJson.length == 0) return searchEl.setAttribute('style', 'display: none'); //

            // 2 - Punjenje niza modelima proizvoda, za uporedjivanje
            productsJson.forEach(bagType => {
                bagType.forVacuumCleaners.forEach(vacuumType => searchTerms.push(vacuumType));
            });
            // console.log(searchTerms); //

            showResults(input.toLocaleLowerCase()); //

            input.length > 0 ? searchEl.setAttribute('style', 'display: block') : searchEl.setAttribute('style', 'display: none');

            // Reset na klik menija
            const menuBtn = document.querySelector('.header-nav-icon__menu--open');
            if(menuBtn){
                menuBtn.addEventListener('click', function(){
                    searchEl.setAttribute('style', 'display: none');
                });
            }
        } catch(err){
            console.log(err);
        }
        
    });
}

