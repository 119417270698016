import axios from 'axios';

import { hideAlert, showAlert } from './alert.js';




export function addToCartProductsPage(){
    let url = location.href;
    const addBtns = document.querySelectorAll('.product-box .main-btn');

    for(let i = 0; i < addBtns.length; i++){
        addBtns[i].addEventListener('click', async function(e){
            e.preventDefault();
            const productId = e.target.dataset.id;

            try {
                await axios({
                    method: 'post',
                    url: `/cart/${productId}`
                });
                location.assign(url);
            
            } catch(err) {
                console.log(err);
            }
        });
    }

}


export function addToCartSingleProductPage(){
    let url = location.href;
    const addBtn = document.querySelector('.product-second-col .main-btn');
    const productId = addBtn.dataset.id;

    addBtn.addEventListener('click', async function(){
        const addQuantity = parseInt(document.querySelector('.product-details__quantity').value);

        try {
            const res = await axios({
                method: 'post',
                url: `/cart/${productId}`,
                data: {
                    addQuantity: addQuantity
                }
            });
            location.assign(url);

        } catch(err) {
            console.log(err);
        }
    });

}


export function goToCart() {
    const cartBtn = document.querySelector('.header__icon--cart path');
    cartBtn.addEventListener('click', () => location.href = '/korpa');
}


export function increaseInCart(){
    let url = location.href;
    const signBtns = document.querySelectorAll('.quantity');

    for(let i = 0; i < signBtns.length; i++){
        signBtns[i].addEventListener('click', async function(e){
            // 1 - Klik na plus
            if(e.target.closest('.quantity__sign--plus')){
                const productId = e.target.closest('.quantity__sign--plus').dataset.id;
                try {
                    await axios({
                        method: 'post',
                        url: `/cart/${productId}`
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            } else if (e.target.closest('.quantity__sign--minus')){

            // 2 - Klik na minus
                const productId = e.target.closest('.quantity__sign--minus').dataset.id;
                try {
                    await axios({
                        method: 'post',
                        url: `/cart/${productId}`,
                        data: {
                            addQuantity: -1
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            }
            
        });
    }


}



export function deleteItem(){
    let url = location.href;
    const removeBtns = document.querySelectorAll('.remove');

    for (let i = 1; i < removeBtns.length; i++ ){

        // Klik na kantu
        removeBtns[i].addEventListener('click', async function(e){
            if(e.target.closest('.remove')){
                const target = e.target.closest('.remove');
                const productId = target.dataset.id;
                const oldQuantity = target.parentElement.children[4].children[1].textContent * 1;

                try {
                    await axios({
                        method: 'post',
                        url: `/cart/${productId}`,
                        data: {
                            addQuantity: oldQuantity * -1
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }

            }
        });

    }
    
}


export function regulateDelieveryAndTotalPrice(){
    const oldDelivery = document.querySelector('.summary-table--delivery').textContent;
    let deliveryField = document.querySelector('.summary-table--delivery');
    const deliveryAmount = 300;
    const offsetPrice = 2000;

    const productsPrice = document.querySelector('.summary-table--products').dataset.productsprice * 1;
    const oldTotalPrice = document.querySelector('.summary-table--total-price').dataset.totalprice * 1;
    let totalPrice = document.querySelector('.summary-table--total-price');

    const btns = document.querySelectorAll('.radio-group--delivery input');
    btns.forEach(btn => btn.addEventListener('click', function(e){
        if(e.target.dataset.acceptance == 'from-delivery'){
            deliveryField.textContent = oldDelivery;
            totalPrice.textContent = `${oldTotalPrice.toLocaleString()} rsd`;

        } else if(e.target.dataset.acceptance == 'at-shop'){
            console.log(productsPrice, oldTotalPrice); //

            deliveryField.textContent = '0 rsd';
            totalPrice.textContent = `${productsPrice.toLocaleString()} rsd`;
        }
    }));
}


export async function collectPurchaseData(){
    const cart = [];
    let item = {}
    let productItems = document.getElementsByClassName('main-table__product-row');

    let productsTotalPrice;
    let delivery;
    let totalPrice;

    const orderBtn = document.querySelector('.section-billing-details .main-btn');

    let selectedPayment;
    let acceptanceMethod;

    let fullName;
    let phone;
    let address;
    let postCode;
    let email;
    let message;

    const collectData = async () => {

        // 1 - Prikupljanje podataka

        for (let i = 0; i < productItems.length; i++) {
            item = {
                name: productItems[i].children[2].textContent,
                price: productItems[i].children[3].textContent,
                quantity: productItems[i].children[4].children[1].textContent,
                subtotal: productItems[i].children[5].textContent
            }
            cart.push(item);
        }
    
        productsTotalPrice = document.querySelector('.summary-table .summary-table__row').children[1].textContent;
        delivery = document.querySelector('.summary-table .summary-table--delivery').textContent;
        totalPrice = document.querySelector('.summary-table .summary-table--total-price').textContent;

        if(document.querySelector('.radio-group--payment input[type="radio"]:checked')) {
            selectedPayment = document.querySelector('.radio-group--payment input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite način plaćanja') }
        
        if(document.querySelector('.radio-group--delivery input[type="radio"]:checked')){
            acceptanceMethod = document.querySelector('.radio-group--delivery input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite način preuzimanja') } 
        

        fullName = document.getElementById('name').value;
        if(!fullName) return showAlert('error', 'Unesite ime');

        phone = document.getElementById('phone').value;
        if(!phone) return showAlert('error', 'Unesite telefon');

        address = document.getElementById('address').value;
        if(!address) return showAlert('error', 'Unesite adresu');

        postCode = document.getElementById('post-code').value;
        if(!postCode) return showAlert('error', 'Unesite poštanski broj');

        email = document.getElementById('email').value;
        if(!email) return showAlert('error', 'Unesite email');

        message = document.getElementById('message').value;

        // console.log(productsTotalPrice, delivery, totalPrice);
        // console.log(fullName, phone, address, postCode, email, message);
        // console.log(selectedPayment);
        // console.log(acceptanceMethod);


        // 2 - Slanje emaila ka kupcu i prodavcu

        try{
            const successfullBooking = await axios({
                url: '/booking/emails',
                method: 'post',
                data: {
                    email,
                    fullName,
                    address,
                    postCode,
                    phone,
                    message,
                    delivery,
                    productsTotalPrice,
                    totalPrice,
                    acceptanceMethod,
                    selectedPayment
                }
            });
            // console.log(successfullBooking); // **

            if(successfullBooking.status == 200){
                showAlert('success', 'Uspešna porudžbina. Proverite Vaš email');
    
                // 3 - Brisanje korpe
                await axios({
                    url: '/cart',
                    method: 'delete'
                });
    
                // 4 - Usmeravanje na zeljenu stranu
                window.setTimeout(() => location.assign('/'), 5 * 1000);
            }
        } catch(err){
            console.log(err);
        }
      
    }


    orderBtn.addEventListener('click', collectData);

}