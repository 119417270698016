

export default () => {
    const pageType = document.querySelector('main').dataset.pagetype;
    const startUrl = location.href;

    const currentPage = document.querySelector('.current-page').textContent;
    let finalUrl;
    const btnPrev = document.querySelector('.page-numbers__left');
    const btnNext = document.querySelector('.page-numbers__right');

    // console.log(startUrl);
    // console.log(currentPage);
    if(btnNext){
        btnNext.addEventListener('click', function(e){
            e.preventDefault();
            if(!startUrl.includes('page=')){
                if (pageType == 'products'){
                    // 1 - na strani brand pretrage
                    finalUrl = `${startUrl}?page=2`;
                } else if (pageType == 'customsearchproducts'){
                    // 2 - na strani proizvoljne pretrage
                    finalUrl = `${startUrl}&page=2`;
                }
                
            } else {
                finalUrl = `${startUrl.split('page=')[0]}page=${currentPage * 1 + 1}`;
            }
            location.href = finalUrl;
        });
    }
    

    if(btnPrev){
        btnPrev.addEventListener('click', function(e){
            e.preventDefault();
            if(startUrl.includes('page=')){
                finalUrl = `${startUrl.split('page=')[0]}page=${currentPage * 1 - 1}`;
                location.href = finalUrl;
            }
        });
    }
    
    
}