
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import mobMenu from './mobileMenu.js';
import correctPageNumber from './correctPageNumber.js';
import customSearch from './customSearch.js';
import searchAutoComplete from './searchAutoComplete.js';
import { additionalProductData } from './productPage.js';
import { addToCartProductsPage, addToCartSingleProductPage, goToCart, increaseInCart, deleteItem, regulateDelieveryAndTotalPrice, collectPurchaseData } from './cart.js'; 
import { sendMessage } from './contact.js';
import { map } from './leaflet.js';
import { login, logout } from './login.js';
import { addBag, editBag, deleteBag, addBrand, deleteBrand } from './admin.js';
import { acceptCookies } from './acceptCookies.js';




// Identifikovanje stranica
const pageType = document.querySelector('main').dataset.pagetype;


// Sve stranice
// acceptCookies();
mobMenu();
customSearch();
goToCart();
searchAutoComplete();

if(pageType == 'products' || pageType == 'customsearchproducts') {
    correctPageNumber();
    addToCartProductsPage();
}  

if(pageType == 'product') {
    additionalProductData();
    addToCartSingleProductPage();
} 

if(pageType == 'cartPage'){
    increaseInCart();
    deleteItem();
    regulateDelieveryAndTotalPrice();
    collectPurchaseData();
}

if(pageType == 'contact-page'){
    sendMessage();
    map();
}

if(pageType == 'login'){
    login();
}

if(pageType == 'admin'){
    addBag();
    editBag();
    deleteBag();
    addBrand();
    deleteBrand();
    logout();
}



//--
// let checked = document.querySelector('input[type="radio"]:checked');
// const checked = document.querySelector('input[type="radio"]');
// const checked = document.querySelector('input');

// let checked = document.querySelector('.radio-group--delivery input[type="radio"]:checked');
// let btn = document.querySelectorAll('.radio-group--delivery input[type="radio"]')[1];