

export default () => {
    document.querySelector('.custom-search-btn').addEventListener('click', function(e){
        e.preventDefault();

        // Unos prebacujem u mala slova, na serveru (apiFeatures) prilagodjavam poklapanje po malim slovima
        let model = document.getElementById('vacuum-model').value;

        // Regulisanje mogucih lapsusa u unosu
        if(model == 'karcher' || model == 'KARCHER' || model == 'Karcher') model = 'karcher';
        // console.log('Uneta vrednost: ', model); //
        
        if(model) location.href = `${location.pathname.split('/')[0]}/pretraga?customQuery=${model}`;
    });
}