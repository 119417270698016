// export function acceptCookiesLS(){
//     let accept = localStorage.getItem('acceptChoice');
//     const cookieInfo = document.querySelector('.section-cookies');
//     const cookieConfirmBtn = document.querySelector('.cookies__btn');

//     const gaCode = `<p>GA kod test</p>`; // ubaciti GA kod **

//     // const gaCode = `<script async src="https://www.googletagmanager.com/gtag/js?id=UA-201887230-1"> </script><script>window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-201887230-1');</script>`;

//     const confirm = () => {
//         localStorage.setItem('acceptChoice', 'all');
//         cookieInfo.setAttribute('style', 'display: none');
//     }

//     // 1 - Ako nije odabran paket kolacica
//     if(!accept) {
//         // Prikazi informaciju o kolacicima
//         cookieInfo.setAttribute('style', 'display: block');

//         // Osluskuj klik na prihvatanje kolacica
//         cookieConfirmBtn.addEventListener('click', confirm);
//     }

//     // 2 - Ako je odabran paket kolacica
//     if(accept == 'all'){
//         return document.querySelector('head').insertAdjacentHTML('afterbegin', gaCode);
//     }

// }



export function acceptCookies(){
    const allCookies = document.cookie;
    const cookieInfo = document.querySelector('.section-cookies');
    const cookieConfirmBtn = document.querySelector('.cookies__btn');

    const confirm = () => {
        document.cookie = "acceptCookies = yes";
        cookieInfo.setAttribute('style', 'display: none');
    }

    // 1 - Ako nije odabran paket kolacica
    if(!allCookies.includes('acceptCookies=yes')) {
        // Prikazi informaciju o kolacicima
        cookieInfo.setAttribute('style', 'display: block');

        // Osluskuj klik na prihvatanje kolacica
        cookieConfirmBtn.addEventListener('click', confirm);
    }

}